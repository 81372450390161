.info_BigWord {
  font-size: 48px;
  text-align: left;
}
.attend_info_ {
  display: absolute;
  justify-content: center;
  text-align: center;
}
.imageinfo {
  margin: 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  width: 16%;
}
.infotext {
  font-size: 60px;
  text-align: center;
  justify-content: center;

  line-height: 48px;
  color: rgb(255, 255, 255);
  display: flex;
  font-family: "Noto Sans TC", monospace;
}

.info {
  margin-top: 50px;
  height: 100px;
  width: 100%;
  display: flex;
  text-align: center;

  left: 50%;

  justify-content: center;

  font-family: "Noto Sans TC", monospace;
}

.date {
  font-family: "Noto Sans TC", monospace;
  border-radius: 20px;
  border-style: solid;
  border-color: rgb(247, 180, 36);
  height: 150px;
  width: 50%;

  background-color: rgb(255, 255, 255);

  position: relative;
  left: 25%;

  justify-content: center;
  display: flex;
  margin-bottom: 50px;
}

.datetitle {
  font-family: "Noto Sans TC", monospace;
  font-size: 200%;
  color: rgb(0, 0, 0);
  text-align: left;
  position: absolute;
  left: 5%;
  top: 25%;
  display: flex;
}

.dateicon {
  height: 80%;
  width: 16%;
  margin-left: 75%;
  top: 10%;

  position: absolute;
}

.place {
  font-family: "Noto Sans TC", monospace;
  border-radius: 20px;
  border-style: solid;
  border-color: rgb(247, 180, 36);
  height: 150px;
  width: 50%;

  background-color: rgb(255, 255, 255);

  position: relative;
  left: 25%;

  justify-content: center;

  display: flex;
  margin-bottom: 50px;
}

.placetitle {
  font-family: "Noto Sans TC", monospace;
  font-size: 200%;
  color: rgb(0, 0, 0);
  text-align: left;
  position: absolute;
  left: 5%;
  top: 25%;
  display: flex;
}

.schoolicon {
  height: 80%;
  width: 16%;
  margin-left: 75%;
  top: 10%;

  position: absolute;
}

.timetitle {
  font-family: "Noto Sans TC", monospace;
  font-size: 200%;
  color: rgb(0, 0, 0);
  text-align: left;
  position: absolute;
  left: 5%;
  top: 25%;
  display: flex;
}

.time {
  font-family: "Noto Sans TC", monospace;
  border-radius: 20px;
  border-style: solid;
  border-color: rgb(247, 180, 36);
  height: 150px;
  width: 50%;
  margin-bottom: 50px;

  background-color: rgb(255, 255, 255);

  position: relative;
  left: 25%;

  justify-content: center;

  display: flex;
}

.clockicon {
  height: 80%;
  width: 16%;
  margin-left: 75%;
  top: 10%;

  position: absolute;
}

.solo {
  font-family: "Noto Sans TC", monospace;
  border-radius: 20px;
  margin-bottom: 50px;
  border-style: solid;
  border-color: rgb(247, 180, 36);
  height: 150px;
  width: 50%;

  background-color: rgb(255, 255, 255);

  position: relative;
  left: 25%;

  justify-content: center;

  display: flex;
}

.solotitle {
  font-family: "Noto Sans TC", monospace;
  font-size: 200%;
  color: rgb(0, 0, 0);
  text-align: left;
  position: absolute;
  left: 5%;
  top: 25%;
  display: flex;
}

.soloicon {
  height: 80%;
  width: 16%;
  margin-left: 75%;
  top: 10%;

  position: absolute;
}

.group {
  font-family: "Noto Sans TC", monospace;
  border-radius: 20px;
  border-style: solid;
  margin-bottom: 100px;
  border-color: rgb(247, 180, 36);
  height: 150px;
  width: 50%;

  background-color: rgb(255, 255, 255);

  position: relative;
  left: 25%;

  justify-content: center;

  display: flex;
}

.grouptitle {
  font-family: "Noto Sans TC", monospace;
  font-size: 200%;
  color: rgb(0, 0, 0);
  text-align: left;
  position: absolute;
  left: 5%;
  top: 25%;
  display: flex;
}

.groupicon {
  height: 80%;
  width: 16%;
  margin-left: 75%;
  top: 10%;

  position: absolute;
}

/*
.attend_button {
  font-size: 100%;
  color: white;
  font-weight: bold;
  margin-bottom: 20px;
  display: relative;
  justify-content: space-between;
  margin: 1vw 20vw;
  background-color: $orange-2;
  border-radius: 50px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 15px;
  width: 30%;
  height: 20%;
  left: 50%;
  text-align: center;
  justify-content: center;
  cursor: pointer;
}
*/

.attend_button {
  text-align: center;
  position: relative;
  color: #fff;
  font-size: 120%;
  background-color: $orange-2;
  font-weight: bold;
  border-radius: 50px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  padding-top: 6.5px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 1vw auto;
  width: 220px;
  height: 20%;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
}

.attend_button.effect {
  transition: all 0.2s linear 0s;

  &:before {
    content: "\eac9";
    font-family: "Material Symbols Rounded";
    font-size: 150%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    top: 0;
    opacity: 0;
    height: 100%;
    width: 80px;
    transition: all 0.2s linear 0s;
  }

  &:hover {
    text-indent: -20px;

    &:before {
      opacity: 1;
      text-indent: 0px;
    }
  }
}

.early_bird_text {
  position: relative;
  color: $yellow-1;
  font-weight: bold;
  line-height: 1.8;
  top: 1.2px;
}

.early_bird-notice p {
  position: relative;
  display: flex;
  margin: 5vh auto;
  padding: 5px 5px 6px 27px;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 199px;
  color: #fff;
  background-color: green;
  border-radius: 100px;
  letter-spacing: 1.5px;
}

.early_bird-notice p::before {
  content: "";
  position: absolute;
  width: 11px;
  height: 11px;
  margin-left: -82%;
  margin-top: 0em;
  background-color: #fff;
  border-radius: 50%;
  animation: pulse 2s infinite alternate;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}

.early_bird-label {
  display: inline-block;
  position: relative;
  margin-right: 8px;
  font-size: 0.7em;
  padding: 0.15em 5px 0.25em 24px;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 80px;
  color: #fff;
  background-color: green;
  border-radius: 100px;
  letter-spacing: 1.5px;
}

.early_bird-label::before {
  content: "";
  position: absolute;
  z-index: 3;
  width: 0.7em;
  height: 0.7em;
  margin-left: -1.2em;
  margin-top: 0.45em;
  background-color: #fff;
  border-radius: 50%;
  animation: pulse-small 1.3s infinite alternate;
}

@keyframes pulse-small {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1.3);
  }
}

.doc-link {
  color: $yellow-1;
  text-decoration: underline $yellow-1;
}

.time_up {
  color: red;
}
