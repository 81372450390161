@mixin registerButton() {
  button {
    margin-top: 1rem;
    text-align: center;
    padding: 1.5rem 1.5rem 1.5rem 2rem;
    letter-spacing: 0.75rem;
    text-decoration: none;
    color: #ffffff;
    font-size: 1.5rem;
    background: $gray-bg1;
    border-radius: 30px;
    border: 5px solid #d9d9d9;

    &:hover {
      color: $yellow-1;
      background: black;

      cursor: pointer;
    }
  }
}

main {
  h1 {
    font-size: 2rem;
    text-align: center;
  }

  div.card {
    display: flex;
    justify-content: center;

    section.left {
      margin: 1rem 1rem 5vw 1rem;
      width: 500px;
      //height: 50vw;
      display: flex;
      flex-direction: column;

      position: relative;

      div.imgFlex {
        display: flex;
        width: 450px;

        // img {
        //   width: 100%;
        //   mix-blend-mode: normal;
        // }

        video {
          user-select: none;
          pointer-events: none; // prevent pulse or play
          width: 50%;
          height: 100%;
          object-fit: cover;
        }

        video#v1 {
          mix-blend-mode: lighten; // 透明背景
        }

        video#v2 {
          mix-blend-mode: screen; // 透明背景
        }
      }
    }

    section.right {
      margin: 2rem 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;
      width: 400px;

      div.words {
        align-items: flex-start;
        display: flex;
        flex-flow: column;

        div.h1 {
          padding: 0.5rem 0;
          font-weight: 900;
          font-size: 2.5rem;
          letter-spacing: 1rem;
          color: #ffffff;

          @mixin textBorder() {
            text-shadow: -2px 0 #ffffff, 0 2px rgb(255, 255, 255), 2px 0 rgb(255, 255, 255),
              0 -2px rgb(255, 255, 255);
          }

          span.C {
            @include textBorder();
            border-color: $blue-1;
            color: $orange-1;
          }

          span.S {
            @include textBorder();
            color: $yellow-1;
          }

          span.I {
            @include textBorder();
            color: $orange-2;
            letter-spacing: 2rem; // 字寬
          }

          span.E {
            @include textBorder();
            color: $blue-1;
            letter-spacing: 1.5rem;
          }
        }

        div#subtitle {
          width: 100%;
          margin-top: 1rem;
          text-align-last: center;
          letter-spacing: 3px;
          font-family: "Source Code Pro", monospace;
          font-style: normal;
          font-weight: 600;
          font-size: 1.5rem;
          line-height: 44px;

          color: #ffffff;
        }
      }

      div.registerButton {
        a {
          @include registerButton();
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  main {
    div.card {
      display: flex;
      flex-direction: column;
      align-items: center;

      section.left {
        order: 2;
        width: 80vw;
        // height: ;
        //width: fit-content;

        div.imgFlex {
          //position: absolute;
          width: fit-content;
        }
      }

      section.right {
        width: 90vw;
        padding: 0;
        order: 3;
        transform: scale(0.9);
        // transform: scale(1);
        // transition: all 1s ease-out;

        // &:hover {
        //   animation: forwards;
        //   transform: scale(1.1);
        //   transition: all 1s ease-in;
        // }

        div.words {
          div.h1 {
            font-size: 2rem;
          }
        }
      }
    }
  }
}

h2.home_titles {
  span {
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    margin-top: 2vw;
    margin-left: 4vw;
    margin-right: 4vw;
  }
}

a.home_titles_image {
  margin-left: 4vw;
  margin-right: 4vw;
}
