$black: #000000;
$white: #ffffff;
// $orange: #ff7a00;
$light_orange: #ffead8;
$red: #ff0000;
$blue-green: #19a6a2;
$brown: #794221;

footer {
  display: flex;
  width: 100%;
  // position: absolute;
  // left: 0;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
}

.contact {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
  padding: 10px 32px;
  background: $light_orange;
  border: 12px solid $brown;
  border-radius: 22px;
  margin: 5px;
  margin-bottom: 14px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  animation-name: contact_animation;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

.contact_text {
  margin: 0;
  text-align: center;
}

.contact_title {
  // font-size: 24px;
  font-weight: bolder;
  line-height: 30px;
  color: $black;
  line-height: 1.5em;
}

.contact_content {
  font-size: 14px;
  line-height: 20px;
  color: $black;
  line-height: 1.5em;
}

.before {
  text-align: center;

  .before_title {
    font-size: 24px;
    font-weight: bolder;
    line-height: 30px;
    color: $white;
    line-height: 1.5em;
  }

  a {
    color: $blue-green;
  }
}

.social_media {
  display: flex;
  justify-content: center;

  .social_media_icon {
    width: 32px;
    height: 32px;
    margin: 16px 10px;
  }

  .facebook_icon:hover {
    filter: opacity(0.3) drop-shadow(0 0 0 blue);
  }

  .instagram_icon:hover {
    filter: opacity(0.6) drop-shadow(0 0 0 #ff00a6);
  }

  // .messenger_icon:hover {
  //   filter: invert(100%) opacity(0.8) drop-shadow(0 0 0 blue);
  // }
}

// @media screen and (width<= 620px) {

// }
@media screen and (width: 980px) and (min-height: 1700px) {
  // .contact {
  //     // width: 300px;
  // }
  .contact_title {
    font-size: 36px;
  }

  .contact_content {
    font-size: 18px;
  }
}

@keyframes contact_animation {
  0% {
    background-color: $light_orange;
    transform: translateY(-2px);
  }

  50% {
    background-color: $white;
    transform: translateY(8px);
  }

  100% {
    background-color: $light_orange;
    transform: translateY(-2px);
  }
}
