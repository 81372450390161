div#page_faq {
  .comment {
    color: white;
  }

  .material-symbols-outlined {
    font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 24;
  }

  .grid-container {
    display: grid;
    grid-template-columns: 4fr 1fr 1fr 4fr;
    grid-template-rows: 170px 170px 230px 230px 230px;
    gap: 15px;
    padding-top: 30px;
    padding-left: 12vw;
    padding-right: 12vw;
    padding-bottom: 60px;
    justify-content: center;
    grid-template-areas:
      "cou-2 cou-1 cou-1 cou-1"
      "cou-2 cou-3 cou-3 cou-3"
      "dor-1 dor-1 dor-2 dor-2"
      "reg-2 reg-1 reg-1 reg-1"
      "reg-4 reg-4 reg-4 reg-3";
  }

  .course-1 {
    grid-area: cou-1;
  }
  .course-2 {
    grid-area: cou-2;
  }
  .course-3 {
    grid-area: cou-3;
  }
  .dormitory-1 {
    grid-area: dor-1;
  }
  .dormitory-2 {
    grid-area: dor-2;
  }
  .register-1 {
    grid-area: reg-1;
  }
  .register-2 {
    grid-area: reg-2;
  }
  .register-3 {
    grid-area: reg-3;
  }
  .register-4 {
    grid-area: reg-4;
  }

  .cell {
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    background-color: $off-white;
    border-radius: 20px;
    padding-right: 3vw;
    padding-left: 3vw;
    flex-direction: column;
    text-align: center;
  }

  .cell:hover .question {
    opacity: 0;
    transform: translateY(-67px);
  }

  .cell:hover .response {
    opacity: 1;
    transform: translateY(0px);
  }

  .cell h2,
  .cell p {
    margin: 0;
    text-align: center;
    overflow: visible;
  }

  .question {
    display: grid;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 3px;
    text-align: center;
    opacity: 1;
    height: 0%;
    align-content: center;
    transform: translateY(-7px);
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  }

  .response {
    display: grid;
    height: 0%;
    align-content: center;
    transform: translateY(60px);
    opacity: 0;
    align-items: center;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  }

  .category-course {
    display: flex;
    position: relative;
    transform: translateX(-50%);
    left: 50%;
    height: 1.8em;
    width: 6.5em;
    border-radius: 50px;
    align-items: center;
    align-content: center;
    text-align: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    color: $off-white;
    background-color: $purple;
    /*border: 3px solid $yellow-1;*/
    .text {
      padding-bottom: 0.1em;
      padding-left: 0.3em;
    }
  }

  .category-dormitory {
    display: flex;
    position: relative;
    transform: translateX(-50%);
    left: 50%;
    height: 1.8em;
    width: 6.5em;
    border-radius: 50px;
    align-items: center;
    align-content: center;
    text-align: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    color: $off-white;
    background-color: $yellow-1;
    /*border: 3px solid $yellow-1;*/
    .text {
      padding-bottom: 0.1em;
      padding-left: 0.3em;
    }
  }

  .category-register {
    display: flex;
    position: relative;
    transform: translateX(-50%);
    left: 50%;
    height: 1.8em;
    width: 6.5em;
    border-radius: 50px;
    align-items: center;
    align-content: center;
    text-align: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    color: $off-white;
    background-color: $orange-2;
    /*border: 3px solid $yellow-1;*/
    .text {
      padding-bottom: 0.1em;
      padding-left: 0.3em;
    }
  }

  @media (max-width: 1024px) {
    .grid-container {
      display: grid;
      gap: 15px;
      padding-top: 30px;
      padding-left: 12vw;
      padding-right: 12vw;
      padding-bottom: 60px;
      justify-content: center;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(4, 220px) 190px;
      grid-template-areas:
        "cou-1 cou-2"
        "cou-3 dor-1"
        "dor-2 reg-1"
        "reg-2 reg-3"
        "reg-4 reg-4";
    }
  }

  @media (max-width: 821px) {
    .grid-container {
      display: grid;
      gap: 15px;
      padding-top: 30px;
      padding-left: 12vw;
      padding-right: 12vw;
      padding-bottom: 60px;
      justify-content: center;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(4, 270px) 250px;
      grid-template-areas:
        "cou-1 cou-2"
        "cou-3 dor-1"
        "dor-2 reg-1"
        "reg-2 reg-3"
        "reg-4 reg-4";
    }
  }

  @media (max-width: 700px) {
    .grid-container {
      display: grid;
      gap: 15px;
      padding-top: 30px;
      padding-left: 12vw;
      padding-right: 12vw;
      padding-bottom: 60px;
      justify-content: center;
      grid-template-columns: 1fr;
      grid-template-rows: 230px 270px 200px repeat(5, 230px) 270px;
      grid-template-areas:
        "cou-1"
        "cou-2"
        "cou-3"
        "dor-1"
        "dor-2"
        "reg-1"
        "reg-2"
        "reg-3"
        "reg-4";
    }
  }

  @media (max-width: 376px) {
    .grid-container {
      display: grid;
      gap: 15px;
      padding-top: 30px;
      padding-left: 12vw;
      padding-right: 12vw;
      padding-bottom: 60px;
      justify-content: center;
      grid-template-columns: 1fr;
      grid-template-rows: 230px 275px 230px repeat(5, 230px) 275px;
      grid-template-areas:
        "cou-1"
        "cou-2"
        "cou-3"
        "dor-1"
        "dor-2"
        "reg-1"
        "reg-2"
        "reg-3"
        "reg-4";
    }
  }
}
