* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Noto Sans TC", sans-serif;
  //position: relative;
}

$orange-1: #dc6a0b;
$yellow-1: #e7ae16;
$blue-1: #083a75;
$gray-bg1: rgba(217, 217, 217, 0.35);
// for 2024 main color below
$off-white: #ffead8;
$light-blue: #19a6a2;
$orange-2: #da5512;
$purple: #433083;

html,
body,
body > #root,
#root > .App {
  //height: 100%;
  //width: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

#root > .App {
  overflow-y: auto;
  background-image: url("../image/bg_nobg.png"), linear-gradient(to right, #352e72ff, #351a37ff);
  background-size: 100%;
  background-position: cover;
  background-repeat: repeat;
  //background-attachment: scroll;
  background-attachment: fixed;
}

@media (max-width: 820px) {
  #root > .App {
    background-image: url("../image/bg_nobg_long.png"),
      linear-gradient(to right, #352e72ff, #351a37ff);
  }
}

#bg {
  position: relative;
  top: 0;
  //bottom: 0;
  left: 0;
  //right: 0;
  width: 100%;
  height: 100%;
  //z-index: -1;
  display: flex;
  flex-direction: column;
  //align-content: space-between;
  //background-image: url(image/Blue_background.png);
  // background-size: 100% 100%;
  // background-position: center center;
  // background-repeat: no-repeat;
  // background-attachment: fixed;
}

// #container{
//   background-image: url(image/Blue_background.png);
//   background-size: cover;
//   background-position: center;
//   background-repeat: no-repeat;
//   background-attachment: fixed;
// }

body {
  z-index: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

a {
  text-decoration: none;
  color: $yellow-1;
}

@import "./nav";
@import "./home";
@import "./footer";
// @import "./scrollbar";
@import "./info";
@import "./about";
@import "./review";
@import "./faq";
@import "./traffic";
@import "./stars";
