* {
  padding: 0;
  margin: 0;
}

body {
  font-family: "Noto Sans TC", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.text-h1 {
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  margin-top: 2vw;
}

.lesson h2 {
  color: #19a6a2;
  font-weight: bold;
  text-align: left;
  // margin-bottom: 15px;
}

p {
  overflow: hidden;
  color: #000000;
  text-align: left;
  line-height: 1.5;
  margin-top: 1rem;
}

.class-list {
  padding-left: 2rem;
}

.about {
  text-align: left;
  margin: 1vw 20vw;
  background-color: #ffead8;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 3%;
}

.lesson {
  display: flex;
  justify-content: space-between;

  margin: 1vw 20vw;
  background-color: #ffead8;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 3%;
}

.image {
  margin: 2rem 0rem 2rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  @media screen and (max-width: 770px) {
    height: 35px;
    width: 35px;
    margin: 0;
  }
}

@media screen and (max-width: 770px) {
  .about,
  .lesson {
    margin: 5vw;
  }
}
